import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import TextComponent from "../components/ui/TextComponent";
import { TitleComponent } from "../components/ui/TitleComponent";
import MarginComponent from "../components/ui/MarginComponent";

export default function IndexPage({ location }) {
  return (
    <Layout
      location={location.pathname}
      title="Веб-студия Jahondev"
      description="Заказывайте сайт у настоящих профессионалов своего дела. Разработка корпоративного сайта, создание лендинга, разработка интернет-магазина. Разработка сайтов любой сложности."
    >
      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Веб-студия JAHONDEV.UZ</TitleComponent>
      </MarginComponent>
      <MarginComponent small="sm:mt-4" margin="mt-5">
        <TextComponent>
          <div>
            Веб-студия «JAHONDEV.UZ» - это команда профессионалов с огромным опытом
            работы. В сфере веб-разработки наша миссия - разработка сайтов,
            которые являются эффективными маркетинговыми инструментами для наших
            клиентов.
          </div>
          <br />
          <div>
            Наша компания начала свою деятельность с 2020 года в городе Андижан.
            За это время мы успешно реализовали более 10 веб-проектов по всему
            Узбекистану, среди которых корпоративные сайты, интернет-магазины,
            лендинг сайты. Если Вы доверите разработку своего сайта веб-студии
            «JAHONDEV.UZ», то можете быть уверены, что Вы получите современный
            качественный сайт, отличные цены и всестороннюю поддержку.
          </div>
          <br />
          <div>
            Если Вам нужен качественный, современный и надежный сайт -
            веб-студия «JAHONDEV.UZ» - Ваш выбор.
          </div>
        </TextComponent>
      </MarginComponent>
    </Layout>
  );
}

export const query = graphql`
  query {
    allSanityProjects(filter: {}, sort: { fields: _createdAt, order: ASC }) {
      nodes {
        name
        id
        image {
          asset {
            id
            gatsbyImageData(width: 700)
          }
        }
      }
    }
  }
`;
